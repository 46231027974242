import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import BasePullDownToRefresh from '@/components/base/basePullDownToRefresh';
import BaseTopNav from '@/components/base/baseTopNav';
import { WalletTypeEnum } from '@/enums/businessEnum';
import CoinOrderList from '@/pages/order/index/components/CoinOrderList';
import ContractOrderList from '@/pages/order/index/components/ContractOrderList';
import useUrlState from '@ahooksjs/use-url-state';
import { t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';

export default () => {
  // 基础定义
  const [urlState] = useUrlState<{ type: WalletTypeEnum }>({
    type: WalletTypeEnum.CONTRACT,
  });
  const { type } = urlState || {};
  // 动态标题
  const renderTitleMap = useMemo(
    () => ({
      [WalletTypeEnum.FASTCONTRACT]: t('极速合约订单'),
      [WalletTypeEnum.COIN]: t('币币订单'),
      [WalletTypeEnum.CONTRACT]: t('合约订单'),
      [WalletTypeEnum.FIAT]: t('股票订单'),
    }),
    [],
  );
  // 下拉重新加载页面
  const [pageKey] = useState(1);
  const orderRef = useRef<any>(null);
  const [loadingDisplay, setLoadingDisplay] = useState(false);
  // 监听
  useEffect(() => {
    const interval = setInterval(() => {
      if (orderRef.current) {
        setLoadingDisplay(orderRef.current?.loadingDisplay);
      }
    }, 100); // 每100毫秒检查一次
    return () => clearInterval(interval); // 清除定时器
  }, []);
  return (
    <BasePullDownToRefresh
      refreshFn={() => {
        // 下拉刷新
        orderRef.current?.refreshOrder?.();
        return true;
      }}
      key={pageKey}
    >
      {/*移动端才显示标题*/}
      <BaseTopNav
        title={renderTitleMap[type as keyof typeof renderTitleMap]}
        rightNode={
          <>
            {loadingDisplay && (
              <div className={'flex items-center justify-center w-fit h-full mr-4 '}>
                <SvgSpinnersBarsRotateFade />
              </div>
            )}
          </>
        }
      />
      {/* 列表 */}
      {type === WalletTypeEnum.CONTRACT && (
        <ContractOrderList ref={orderRef} isShowLoadingDisplay={false} />
      )}
      {type === WalletTypeEnum.COIN && (
        <CoinOrderList ref={orderRef} isShowLoadingDisplay={false} />
      )}
    </BasePullDownToRefresh>
  );
};
